import { Dummy } from '../models/dummy';
import { PaginatedList } from '../models/paginatedList';
import { request } from './request';

const BaseUrl = 'https://localhost:7278/api';

export const dummyService = {
  async getById(id: number) {
    return await request<Dummy>(`${BaseUrl}/dummy/${id}`).catch((e) => {
      console.error(e);
      return null;
    });
  },
  async getPaged(pageIndex: number, pageSize: number) {
    return await request<PaginatedList<Dummy>>(
      `${BaseUrl}/dummy?pageIndex=${pageIndex}&pageSize=${pageSize}`,
    ).catch((e) => {
      console.error(e);
      return [];
    });
  },
  async getAll(): Promise<Dummy[] | null> {
    return [
      { id: 1, firstName: 'John', lastName: 'Doe', dateOfBirth: new Date() },
      { id: 2, firstName: 'Ben', lastName: 'Benowski', dateOfBirth: new Date() },
      { id: 3, firstName: 'Emma', lastName: 'Doe', dateOfBirth: new Date() },
      { id: 4, firstName: 'Patricia', lastName: 'Hazel', dateOfBirth: new Date() },
      { id: 5, firstName: 'Sam', lastName: 'Mars', dateOfBirth: new Date() },
    ];
  },

  // Using `extends` to set a type constraint:
  // post: <TBody extends BodyInit, TResponse>(url: string, body: TBody) => request<TResponse>(url, { method: 'POST', body })
};
