export class User {
  firstName: string;
  lastName: string;
  email: string;

  constructor(firstName: string, lastName: string, email: string) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
  }

  displayName() {
    return `${this.firstName} ${this.lastName}`;
  }

  letterAvatar() {
    return this.firstName.slice(0, 1);
  }
}