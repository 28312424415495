import { useRoutes, Navigate } from 'react-router-dom';
import { MainLayout } from './layouts/MainLayout';
import { DummiesPage } from './pages/DummiesPage';
import { EmptyPage } from './pages/EmptyPage';

const Router = () => {
  const routes = useRoutes([
    {
      element: <MainLayout />,
      children: [
        { path: '/', element: <Navigate to="/empty" /> },
        { path: 'empty', element: <EmptyPage /> },
        { path: 'dummies', element: <DummiesPage /> },
      ],
    },
    { path: '/status', element: <div>Version: {window.__RUNTIME_CONFIG__.REACT_APP_VERSION!}</div> },
  ]);

  return routes;
};

export default Router;
