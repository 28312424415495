import React from 'react';
import { Dummy } from '../../models/dummy';
import { ListItem } from './ListItem';

export interface ListingProps {
  listItems: Dummy[];
}

export const Listing: React.FC<ListingProps> = ({ listItems }) => {
  return (
    <div>
      {listItems.map((item) => (
        <div key={item.id}>
          <ListItem item={item} />
        </div>
      ))}
    </div>
  );
};
