import React from 'react';
import { borderColor, borders, height, margin, textColor, twCls, width } from 'style';
import { Dummy } from '../../models/dummy';

export interface ListItemProps {
  item: Dummy;
}

export const ListItem: React.FC<ListItemProps> = ({ item }) => {
  return (
    <div
      className={twCls(
        height('h-20'),
        margin('m-auto'),
        width('w-4/5'),
        borders('border-b'),
        borderColor('border-b-gray-200'),
        textColor('text-black'),
      )}
    >
      <div>
        {item.firstName} {item.lastName}
      </div>
      <div>born: {item.dateOfBirth.getFullYear()}</div>
    </div>
  );
};
