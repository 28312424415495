import { Outlet } from 'react-router-dom';

import { ResponsiveAppBar } from '../components/ResponsiveAppBar/ResponsiveAppBar';

import { User } from '../models/user';

export const MainLayout = () => {
  const user = new User('FirstName', 'LastName', 'first.last@email.com');

  return (
    <div>
      <ResponsiveAppBar user={user} />
      <Outlet />
    </div>
  );
};
