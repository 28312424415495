import {
  combineCls,
  contentStyle,
  emptySpaceStyle,
  fontSize,
  fontWeight,
  textAlign,
  textDecoration,
  twCls,
} from 'style';
import { Header } from '../components/Header/Header';

export const EmptyPage = () => {
  return (
    <div>
      <Header
        title="Main"
        primaryButtonCaption="Save"
        secondaryButtonCaption="Cancel"
        tertiaryButtonCaption="Delete"
      />
      <div className={emptySpaceStyle}></div>
      <div className={combineCls(contentStyle, textAlign('text-center'))}>
        <h1
          className={twCls(
            fontSize('text-xl'),
            fontWeight('font-bold'),
            textDecoration('underline'),
          )}
        >
          Content styled by Tailwind CSS
        </h1>
      </div>
    </div>
  );
};
