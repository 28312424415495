import * as React from 'react';
import { Link } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';

import logo from '../../logo.svg';
import { User } from '../../models/user';

import {
  backgroundColor,
  display,
  flexGrow,
  fontSize,
  fontWeight,
  height,
  lineHeight,
  margin,
  padding,
  position,
  textAlign,
  textColor,
  textDecoration,
  twCls,
  width,
} from 'style';

export interface ResponsiveAppBarProps {
  user: User;
}

const pages = ['Empty', 'Dummies'];
const settings = ['Profile', 'Account', 'Logout'];

const noUnderline = twCls(textDecoration('no-underline', 'hover:no-underline'));

export const ResponsiveAppBar = ({ user }: ResponsiveAppBarProps) => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar
      className={twCls(backgroundColor('bg-tmp-blue-1'), position('static'))}
      role="navigation"
    >
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <Box
            component="img"
            alt="logo"
            src={logo}
            className={twCls(
              height('h-16'),
              width('w-16'),
              flexGrow('grow-0'),
              display('hidden', 'md:flex'),
            )}
          />

          <Box className={twCls(flexGrow('grow-0'), display('flex', 'md:hidden'))}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              keepMounted
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              className={twCls(display('md:hidden'))}
            >
              {pages.map((page) => (
                <Link key={page} to={page} className={noUnderline}>
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    <Typography className={twCls(textAlign('text-center'))}>{page}</Typography>
                  </MenuItem>
                </Link>
              ))}
            </Menu>
          </Box>

          <Box
            component="img"
            alt="logo"
            src={logo}
            className={twCls(
              display('flex', 'md:hidden'),
              height('h-16'),
              width('w-16'),
              flexGrow('grow'),
            )}
          />

          <Box className={twCls(flexGrow('grow'), display('hidden', 'md:flex'))}>
            {pages.map((page) => (
              <Link key={page} to={page} className={noUnderline}>
                <Button
                  key={page}
                  onClick={handleCloseNavMenu}
                  className={twCls(margin('my-0.5'), textColor('text-white'), display('block'))}
                >
                  {page}
                </Button>
              </Link>
            ))}
          </Box>

          <Box className={twCls(flexGrow('grow-0'), display('hidden', 'md:flex'))}>
            <div className={twCls(margin('m-3'))}>
              <div
                className={twCls(
                  textAlign('text-right'),
                  fontWeight('font-semibold'),
                  fontSize('text-sm'),
                  lineHeight('leading-6'),
                )}
              >
                {user.displayName()}
              </div>
              <div
                className={twCls(
                  textAlign('text-right'),
                  fontWeight('font-normal'),
                  fontSize('text-xs'),
                  textColor('text-tmp-blue-2'),
                )}
              >
                {user.email}
              </div>
            </div>
          </Box>

          <Box className={twCls(flexGrow('grow-0'))}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} className={twCls(padding('p-0'))}>
                <Avatar className={twCls(backgroundColor('bg-tmp-blue-3'))}>
                  {user.letterAvatar()}
                </Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              className={twCls(margin('mt-11'))}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              keepMounted
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography className={twCls(textAlign('text-center'))}>{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
