import { useEffect, useState } from 'react';
import { Dummy } from '../models/dummy';
import { dummyService } from '../services/dummyService';
import { Header } from '../components/Header/Header';
import { Listing } from '../components/Listing';
import { contentStyle, emptySpaceStyle } from 'style';

export const DummiesPage = () => {
  const [dummies, setDummies] = useState<Dummy[] | null>(null);

  useEffect(() => {
    (async () => {
      const response = await dummyService.getAll();
      setDummies(response);
    })();
  }, []);

  const control =
    dummies != null ? (
      dummies.length > 0 ? (
        <Listing listItems={dummies} />
      ) : (
        <div className={emptySpaceStyle}>
          Dummies will be displayed here. Click &apos;Add Dummy&apos;
        </div>
      )
    ) : (
      <div className={emptySpaceStyle}>Could not get dummies from the API</div>
    );

  const primaryButtonOnClick = () => {
    console.log('primary button clicked');
  };

  return (
    <div>
      <Header
        title="Dummies"
        primaryButtonCaption="Add Dummy"
        primaryButtonClick={primaryButtonOnClick}
      />
      <div className={contentStyle}></div>
      {control}
    </div>
  );
};
